import { jsx as ___EmotionJSX } from "@emotion/core";
import staticQueryData from "../../../public/static/d/3712204869.json";
import React from 'react';
import SEO from '../../components/seo';
import '../../components/layout.css';
import { Page, PageTitle, PageSubtitle, BlogListWrapper, BlogArticle, ArticleTitle, ArticleDescription } from '../../styles';

var BlogPage = function BlogPage() {
  var _staticQueryData$data = staticQueryData.data.allMdx,
      totalCount = _staticQueryData$data.totalCount,
      nodes = _staticQueryData$data.nodes;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(SEO, {
    title: "Blog"
  }), ___EmotionJSX(Page, null, ___EmotionJSX(PageTitle, null, "My Blog Posts"), ___EmotionJSX(PageSubtitle, null, "Currently ", totalCount, " Posts"), ___EmotionJSX(BlogListWrapper, null, nodes.map(function (_ref) {
    var _ref$frontmatter = _ref.frontmatter,
        title = _ref$frontmatter.title,
        slug = _ref$frontmatter.slug,
        description = _ref$frontmatter.description;
    return ___EmotionJSX(BlogArticle, {
      to: "/blog/" + slug,
      title: title,
      key: slug
    }, ___EmotionJSX(ArticleTitle, null, title), ___EmotionJSX(ArticleDescription, null, description));
  }))));
};

export default BlogPage;